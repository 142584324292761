
.cml-mapping {
    font-size: 18px;
}

.cml-mapping img {
    width: 175px;
    height: 150px;
}

.cml-mapping .menu a {
    font-family: sans-serif;
    font-weight: bold;
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.cml-mapping .menu a:hover {
    color: #0099ff;
}

.cml-mapping .name {
    color: #0099ff;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }