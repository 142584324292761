.cml-portfolio {
    margin-bottom: 6em;
}

.cml-portfolio h1 {
    margin-bottom: 0.2em;
    font-size: 2.5em;
}

.cml-portfolio .mt-5 {
    margin-top: 1.5rem!important
}

.portfolio-scroll {
    margin: auto;
    width: max-content;
    position: relative;
    padding: 0.2em 0;
}

.portfolio-scroll::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.15em;
    background-color: #01a09e;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
}

.cml-portfolio:hover .portfolio-scroll::after,
.cml-portfolio:focus .portfolio-scroll::after {
    opacity: 1;
    transform: scale(1);
}