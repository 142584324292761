.cml-about {
  margin-bottom: 8em;
}

.cml-about h1 {
  margin-bottom: .2em;
  font-size: 2.5em;
}

.cml-about p {
  font-size: 1.2em;
  line-height: 2;
}

.about-scroll {
  margin: auto;
  width: fit-content;
  position: relative;
  padding: 0.2em 0;
}

.about-scroll::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.15em;
  background-color: #01a09e;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.cml-about:hover .about-scroll::after,
.cml-about:focus .about-scroll::after {
  opacity: 1;
  transform: scale(1);
}