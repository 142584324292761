.cml-portfolio-projects-nav {
  margin-bottom: 2em;
}

.cml-portfolio-projects-nav img {
    height: 375px;
    width: 375px;
  }
  
  .cml-portfolio-projects-nav h1 {
    font-family: "RobotoNormal";
    font-weight: 100;
    font-size: 1.9em;
    text-align: center;
    letter-spacing: 3px;
  }


 .cml-portfolio-projects-nav .content {
    position: relative;
    height: 375px;
    width: 375px;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
  }
  
  .cml-portfolio-projects-nav .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 375px;
    width: 375px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .cml-portfolio-projects-nav .content:hover .content-overlay {
    opacity: 1;
  }
  
  .cml-portfolio-projects-nav .content-details {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .cml-portfolio-projects-nav .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .cml-portfolio-projects-nav .content-details h3 {
    color: #fff;
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .cml-portfolio-projects-nav .fadeIn-top {
    top: 20%;
  }