
  .carousel .slide {
    color: black;
    height: 100%;
  }
  
  .footer-carousel {
    background: #fafafa;
    width: 432px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4%;
    padding-bottom: 8%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 286px;
    transition: all 1s;
  }
  
  .carousel .control-dots {
    padding-left: 5px;
    outline: 0;
    bottom: 3%;
  }
  
  .footer-carousel h3 {
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
  }
  
  .footer-carousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  
  .footer-carousel p {
    font-weight: 100;
    line-height: 29px;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
  }
  
  .footer-carousel p:before {
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .footer-carousel p:after {
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    box-shadow: none;
    background: #454545;
    outline: 0;
  }
  
@media only screen and (max-width: 1600px) {
    .carousel-root {
      outline: 0;
      width: 93%;
      margin: auto;
    }
    
    .footer-carousel {
      background: #fafafa;
      width: 88%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 4%;
      padding-bottom: 8%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 280px;
      transition: all 1s;
    }
  }