
.logo-bbb img {
    border: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 60px;
    margin-top: 50px;
    height: auto;
    width: 45%;
    min-width: 293px;
}