.singleCol {
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  font-size: 1.25rem;
}

a.social:hover {
  transform: translateY(-10px);
}

a.facebook {
  color: #4968ad;
}

a.google {
  color: #d41616;
}

a.twitter {
  color: #00acee;
}

a.instagram {
  color: #cd486b;
}

a.youtube {
  color: #c4302b;
}
