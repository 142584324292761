
.modal {
  background: rgba(0, 0, 0, 0.8);
  /* width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/* @media screen and (min-width: 992px) {
  .modal-wrapper {
    width: 800px;
    height: 600px;
    margin-left: -200px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    position: relative;
    z-index: 10;
    border-radius: 10px;
  }
} */

/* .modal-wrapper {
  width: 800px;
  height: 600px;
  margin-left: -200px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;
  z-index: 10;
  border-radius: 10px;
} */

.image-gallery-slide img {
  width: 100%;
  height: 505px;
}

.image-gallery-thumbnail img {
  width: 100%;
  height: 69px;
}

.fullscreen .image-gallery-slide img {
  width: 100%;
  height: auto;
}

/* .modal-content img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: #000;
} */

/* .image-gallery {
  width: 100%;
  height: auto;
} */

/* .modal-content button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
} */

/* .image-gallery-slide img {
  width: 100%;
  height: 505px;
}

.image-gallery-thumbnail img {
  width: 100%;
  height: 69px;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
} */