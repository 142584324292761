@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.form-container {
  width: 17em;
  background-color: white;
  margin: auto;
  margin-bottom: 4em;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.form-header h1 {
  margin-bottom: 0.1em;
  font-size: 2.5em;
}

.contact-scroll {
  margin: auto;
  margin-bottom: .6em;
  width: max-content;
  position: relative;
  padding: 0.2em 0;
}

.contact-scroll::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.15em;
  background-color: #01a09e;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.form-header:hover .contact-scroll::after,
.form-header:focus .contact-scroll::after {
  opacity: 1;
  transform: scale(1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #8EC298;
  padding: 15px;
  color: white;
}

.error-message {
  font-family: "Roboto", sans-serif;
  background-color: #c50922;
  padding: 15px;
  color: white;
}

.form-field {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
}

.contact-form span {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
}

input {
  background: #f2f2f2;
}

textarea {
  background: #f2f2f2;
}

select {
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

button {
  background: #7DC1CB;
  color: white;
  cursor: pointer;
}

button:disabled {
  cursor: default;
  background: #dddcdb;

}